<template>
    <div>
        <van-nav-bar
                placeholder
                fixed
                left-arrow
                :border='false'
                title="提现详情"
                z-index="99"
                class="header"
                @click-left="$router.go(-1)">
            <template #left>
                <van-icon name="arrow-left" size="25" color="#0A1C33"/>
            </template>
        </van-nav-bar>
        <div class="details">
            <van-icon name="checked" color="#4D77FD" size="40"/>
            <p class="details-title">
                <span>{{detailList.money}}</span> 元
            </p>
            <p class="details-text">提现成功</p>
        </div>
        <div>
            <p class="xian"></p>
            <div class="details">
                <div class="details-button">
                    <p class="details-button-text">到账时间</p>
                    <p class="details-button-time">{{detailList.created_at}}</p>
                </div>
                <div class="details-button">
                    <p class="details-button-text">到账方式</p>
                    <p class="details-button-time" v-if="detailList.type == 1">微信</p>
                    <p class="details-button-time" v-if="detailList.type == 2">支付宝</p>
                </div>
                <div class="details-button">
                    <p class="details-button-text">到账金额</p>
                    <p class="details-button-time">￥{{detailList.money}}</p>
                </div>
            </div>
        </div>
        <!--        <p class="error">很抱歉，您好的我不知道这是什么原因我不知道这是您好的我不知道这是什么原因我不知道这是什么原因</p>-->
        <p class="button" @click="buttonClick">我知道了</p>
    </div>
</template>

<script>
    export default {
        name: "SubsidiaryDetails",
        data() {
            return {
                id: this.$route.query.id,
                detailList: [],
            }
        },
        created() {
            this.detail()
        },
        methods: {
            detail() {
                this.$axios.post('/api/withdraw/detail', {
                    id: this.id
                })
                    .then(res => {
                        this.detailList = res.data.data
                    })
            },
            buttonClick() {
                this.$router.go(-1)
            },
        }
    }
</script>

<style scoped>
    .error {
        color: #909399;
        font-size: 24px;
        padding: 40px 40px 60px 40px;
        line-height: 40px;
        text-align: center;
    }

    .button {
        width: 670px;
        height: 88px;
        border: 1px solid rgba(77, 119, 253, 1);
        border-radius: 44px;
        color: #4D77FD;
        font-size: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 40px;
    }

    .details-button-time {
        color: #909399;
        font-size: 24px;
    }

    .details-button-text {
        color: #303133;
        font-size: 26px;
    }

    .details-button {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 40px;
    }

    .xian {
        height: 20px;
        background: #F2F4F5;
    }

    .details-text {
        color: #606266;
        font-size: 28px;
        margin-top: 35px;
    }

    .details-title {
        color: #606266;
        font-size: 28px;
        text-align: center;
        margin-top: 30px;
    }

    .details-title > span {
        color: #0A1C33;
        font-size: 42px;
        margin-right: 5px;
    }

    .details {
        text-align: center;
        padding: 40px;
    }
</style>